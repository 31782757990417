<template>
  <div v-if="showDiv">
    <h2 class="font-bold text-2xl">{{ stuff.title }}</h2>
    <p>{{ stuff.content }}</p>
  </div>
</template>

<script>
import { ref } from "vue";

const apiMock = async () => {
  await new Promise((resolve, reject) => {
    setTimeout(() => resolve(), 3000);
  });
  const dataToSend = {
    title: "Test title",
    content: "Ipsum to the lorem",
  };
  return dataToSend;
};

export default {
  async setup() {
    const showDiv = ref(false);
    const stuff = ref("");

    stuff.value = await apiMock();
    if (stuff.value) {
      showDiv.value = !showDiv.value;
    }

    return {
      showDiv,
      stuff,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 1rem;
  background-color: #fff;
  box-shadow: 1px 3px 11px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
</style>
